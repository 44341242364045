import React from 'react';
import Nav from '../Nav/HeaderNav';
const item = () =>{
    return(
        <div className="container">
            <Nav/>
        <div className="text-center">
            <h3>Item-List</h3>
            <p>Nothing to show here</p>
        </div>
        </div>
    );
}

export default item;