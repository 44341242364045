import React, { useState } from 'react';
import { toast } from 'react-toastify';
import B2bUser from '../Forms/B2bUser';
import { requestforgotPasswordOtp, signin } from '../Functions/auth';
import logo from '../Images/brand-white.png';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const ForgotPassword = ({ history }) => {
	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [loading, setLoading] = useState(false);
	const [select, setSelect] = useState('');
	const { enqueueSnackbar } = useSnackbar();

	let dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(email);
		setLoading(true);
		requestforgotPasswordOtp(email)
			.then((res) => {
                console.log("Here is Response:",res)
                    enqueueSnackbar(res.data.message,{
                        variant:'success',
                        preventDuplicate:true,
                        autoHideDuration:3000
                    })
                    localStorage.setItem(
                        'forgotPasswordToken',
                        res.data.forgotToken
                    );
                    setLoading(false)
                    history.push("/update-password")
                    // }
                }
            )			
			.catch((err) => {
				enqueueSnackbar(err.message, {
					variant: 'error',
					preventDuplicate: true,
					autoHideDuration: 2000,
				});
				setLoading(false);
			});
	};
	return (
		<div className='container mb-5'>
			<Link to='/'>
				<img src={logo} alt='AH International' className='logoImg' />
			</Link>
			<h3 style={{ color: 'a7a936' }} className='text-center'>
				Forgot Password
			</h3>
			<div className='container-fluid'>
				<div className='row '>
					<div className='col-md-4'></div>
					<div className='col-md-4'>
						<form onSubmit={handleSubmit}>
							<label>Email address</label>
							<input
								className='form-control'
								value={email}
								onChange={(e) =>
									setEmail(e.target.value.toLowerCase())
								}
								placeholder='email@address.com'
							/>
							
							<button
								className='button mt-3'
								style={loading ? { pointerEvents: 'none' } : {}}
							>
								{loading ? 'Loading..' : 'Request for Otp'}
							</button>								
						</form>
						<h5 className="mt-4 text-center" onClick={()=>history.push('/existing-user')} style={{cursor:'pointer'}}>Back to login!</h5>
					</div>
					<div className='col-md-4'></div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
