import React, { useState } from 'react';
import data from '../countries.json';
import { signup } from '../Functions/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Hidden } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const B2bUser = () => {
	const [name, setName] = useState('');
	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [desg, setDesg] = useState('');
	const [loading, setLoading] = useState(false);
	// const [dep, setDep] = useState('');
	// const [country, setCountry] = useState('');
	// const [pincode, setPincode] = useState('');
	// const [states, setState] = useState('');
	// const [dis, setDis] = useState('');
	// const [adrs1, setAdrs1] = useState('');
	// const [adrs2, setAdrs2] = useState('');
	const [password, setPassword] = useState('');

	let history = useHistory();
	let dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const onHandleSumbit = (e) => {
		e.preventDefault();
		if (name === '' || email === '' || phone === '' || password === '') {
			enqueueSnackbar('Please Enter all the fields', {
				variant: 'warning',
				preventDuplicate: true,
			});
		} else {
			setLoading(true);
			signup(
				name,
				email,
				password,
				phone,
				'B2B',
				// address,
				company,
				desg
				// dep
			)
				.then((res) => {
					console.log('we got the response');
					console.log(res);
					if (
						res.data.message === 'User was registered successfully!'
					) {
						history.push('/existing-user');
						enqueueSnackbar(
							'Your Account has been succesfully registered',
							{
								variant: 'success',
								preventDuplicate: true,
							}
						);
					}
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					enqueueSnackbar(err.response.data.message, {
						variant: 'error',
						preventDuplicate: true,
					});
					setLoading(false);
				});
		}
	};

	return (
		<div>
			<form onSubmit={onHandleSumbit}>
				<label>Full Name</label>
				<input
					className='form-control'
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder='First and Last Name'
				/>
				<label>Designation</label>
				<input
					className='form-control'
					value={desg}
					onChange={(e) => setDesg(e.target.value)}
					placeholder='Manager,Chef...'
				/>
				{/* <label>Department</label>
				<select
					className='form-select'
					onChange={(e) => {
						setDep(e.target.value);
					}}>
					<option>Please Choose a value</option>
					<option>Owner</option>
					<option>Purchase</option>
					<option>Consultant</option>
					<option>Genral Manager</option>
					<option>Chef</option>
					<option>Food &amp; beverage</option>
					<option>House Keeping</option>
					<option>Front Office</option>
					<option>Engineering Purchase</option>
					<option>Loss &amp; Prevention</option>
				</select> */}
				<label>Company name</label>
				<input
					className='form-control'
					value={company}
					onChange={(e) => setCompany(e.target.value)}
					placeholder='AH International'
				/>
				<label>Email address</label>
				<input
					className='form-control'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder='email@address.com'
				/>
				<label>Password</label>
				<input
					className='form-control'
					type='password'
					value={password}
					placeholder='********'
					onChange={(e) => setPassword(e.target.value)}
				/>
				<label>Phone Number</label>
				<input
					className='form-control'
					value={phone}
					style={{ fontFamily: 'helvetica' }}
					onChange={(e) => setPhone(e.target.value)}
					placeholder='(+91)7894523169'
				/>
				{/* <label>Address</label>
				<div className='row'>
					<select
						className='form-select'
						style={{
							width: '95%',
							margin: '0 0.8rem 1.5rem 0.8rem',
						}}
						onChange={(e) => {
							setCountry(e.target.value);
						}}>
						<option>Choose your country</option>
						{data.countryList.map((country) => {
							return (
								<option key={country} value={country}>
									{country}
								</option>
							);
						})}
					</select>
					<div className='col-md-6'>
						<input
							className='form-control'
							value={states}
							onChange={(e) => setState(e.target.value)}
							placeholder='State'
						/>
					</div>
					<Hidden mdUp>
						<div className='col-md-6 mt-4'>
							<input
								className='form-control'
								value={pincode}
								style={{ fontFamily: 'helvetica' }}
								onChange={(e) => setPincode(e.target.value)}
								placeholder='Zip Code'
							/>
						</div>
					</Hidden>
					<Hidden smDown>
						<div className='col-md-6'>
							<input
								className='form-control'
								value={pincode}
								style={{ fontFamily: 'helvetica' }}
								onChange={(e) => setPincode(e.target.value)}
								placeholder='Zip Code'
							/>
						</div>
					</Hidden>
				</div>
				<br />
				<input
					className='form-control'
					value={dis}
					onChange={(e) => setDis(e.target.value)}
					placeholder='District'
				/>
				<br />
				<input
					className='form-control'
					value={adrs1}
					onChange={(e) => setAdrs1(e.target.value)}
					placeholder='Address Line 1'
				/>
				<br />
				<input
					className='form-control'
					value={adrs2}
					onChange={(e) => setAdrs2(e.target.value)}
					placeholder='Address Line 2'
				/> */}
				<button
					className='button mt-3'
					style={loading ? { pointerEvents: 'none' } : {}}
				>
					{loading ? 'Loading.' : 'Submit'}
				</button>
			</form>
		</div>
	);
};

export default B2bUser;
