import React, { useState } from 'react';
import { toast } from 'react-toastify';
import B2bUser from '../Forms/B2bUser';
import { signin } from '../Functions/auth';
import logo from '../Images/brand-white.png';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const ExistingUser = ({ history }) => {
	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [loading, setLoading] = useState(false);
	const [select, setSelect] = useState('');
	const { enqueueSnackbar } = useSnackbar();

	let dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(email, pwd);
		setLoading(true);
		signin(email, pwd)
			.then((res) => {
				// console.log(res.data);
				dispatch({
					type: 'LOGGED_IN_USER',
					payload: {
						id: res.data.id,
						name: res.data.name,
						email: res.data.email,
						role: 'retail',
					},
				});
				localStorage.setItem(
					'userData',
					JSON.stringify({
						id: res.data.id,
						name: res.data.name,
						email: res.data.email,
						role: res.data.role,
					})
				);
				setLoading(false);
				history.push('/product-scan');
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar('Invalid User', {
					variant: 'error',
					preventDuplicate: true,
					autoHideDuration: 2000,
				});
				setLoading(false);
			});
	};
	return (
		<div className='container mb-5'>
			<Link to='/'>
				<img src={logo} alt='AH International' className='logoImg' />
			</Link>
			<h3 style={{ color: 'a7a936' }} className='text-center'>
				Existing User
			</h3>
			<div className='container-fluid'>
				<div className='row '>
					<div className='col-md-4'></div>
					<div className='col-md-4'>
						<form onSubmit={handleSubmit}>
							<label>Email address</label>
							<input
								className='form-control'
								value={email}
								onChange={(e) =>
									setEmail(e.target.value.toLowerCase())
								}
								placeholder='email@address.com'
							/>
							<label>Password</label>
							<input
								type='password'
								className='form-control'
								value={pwd}
								onChange={(e) => setPwd(e.target.value)}
								placeholder='xxxxxxxxx'
							/>
							<button
								className='button mt-3'
								style={loading ? { pointerEvents: 'none' } : {}}
							>
								{loading ? 'Loading..' : 'Login'}
							</button>								
						</form>
						<h5 className="mt-2 text-center" onClick={()=>history.push('/forgot-password')} style={{cursor:'pointer'}}>Forgot Password?</h5>
					</div>
					<div className='col-md-4'></div>
				</div>
			</div>
		</div>
	);
};

export default ExistingUser;
