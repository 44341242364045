import React, { useEffect, useState } from 'react';
import logo from '../Images/brand-white.png';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
const Nav = () => {
	const [loading, setLoading] = useState(false);

	const [openLogout, setOpenLogout] = useState(false);

	const history = useHistory();

	// useEffect(() => {
	//   if (
	//     // window.location.href === "https://qr-scanner-cae95.web.app/product-scan"

	//   ) {
	//     setLoading(true);
	//   } else {
	//     setLoading(false);
	//   }
	// });
  

	let dispatch = useDispatch();
	const handleLogout = () => {
		localStorage.removeItem('userData');
		history.push('/existing-user');
	};
	return (
		<>
			<div style={{ display: 'inline' }}>
				<img src={logo} alt='AH International' className='logoImg' />
				<div className='options'>
					{/* <Link style={{ color: "#000" }} to="/product-scan">
            <i
              style={{ fontSize: "30px", margin: "0rem 0.3rem" }}
              className="fas fa-qrcode"
            ></i>
          </Link> */}
					{/* <Link
            onClick={handleLogout}
            style={{ color: "#000" }}
            to="/existing-user"
          > */}
					<i
						onClick={() => {
							setOpenLogout(true);
						}}
						style={{ fontSize: '30px', margin: '0rem 1rem' }}
						className='fas fa-sign-out-alt'></i>

					{/* </Link> */}
					{/* <Link style={{ color: "#000" }} to="/store">
            <i
              style={{ fontSize: "30px", margin: "0rem 1rem" }}
              className="fas fa-book"
            ></i>
          </Link> */}
					{/* <Link style={{ color: "#000" }} to="/cart">
            <i
              style={{ fontSize: "32px", margin: "0rem 0.3rem" }}
              className="fas fa-shopping-cart"
            ></i>
          </Link> */}
				</div>
			</div>
			{openLogout ? (
				<div id='myModal' class='modal' style={{ display: 'block' }}>
					<div class='modal-content'>
						<div className='card'>
							<div className='card-body'>Are you sure you want to Logout?</div>
							<div
								style={{
									justifyContent: 'center',
								}}
								className='card-footer justify-content-center'>
								<button
									className='button close-modal ml-5 mr-5'
									onClick={() => {
										setOpenLogout(false);
										handleLogout();
									}}>
									Yes
								</button>
								<button
									className='button close-modal'
									onClick={() => {
										setOpenLogout(false);
									}}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default Nav;
