import axios from 'axios';

export const signup = async (
	name,
	email,
	password,
	phone,
	role,
	companyName = 'empty',
	designation = 'empty'
) => {
	let lower_case_email = email.toLowerCase();

	console.log('email : ', lower_case_email);
	console.log('password : ', password);
	console.log('name : ', name);
	console.log('phone : ', phone);
	console.log('company name : ', companyName);
	console.log('role : ', role);
	console.log('designation : ', designation);

	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/auth/signup`,
		{
			role: role,
			name: name,
			email: lower_case_email,
			companyName: companyName,
			phoneNumber: phone,
			designation: designation,
			department: 'department',
			password: password,
		}
	);
};

export const signin = async (email, password) => {
	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/auth/signin`,
		{
			email,
			password,
		}
	);
};

export const getUsers = async () => {
	return await axios.get(`${process.env.REACT_APP_BASEURL}/api/all`);
};

export const getB2BUsers = async () => {
	return await axios.get(`${process.env.REACT_APP_BASEURL}/api/B2B`);
};

export const getRetailUsers = async () => {
	return await axios.get(`${process.env.REACT_APP_BASEURL}/api/retail`);
};

export const getClientInfo = async (clientId) => {
	return await axios.get(
		`${process.env.REACT_APP_BASEURL}/api/client/${clientId}`
	);
};

export const deleteClientById = async (clientId) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASEURL}/api/user/${clientId}`
	);
};

export const requestforgotPasswordOtp=async(email)=>{
	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/auth/get-otp-to-reset-password`,{email}
	)
}

export const updatePasswordWithOtp=async(password,otp,token)=>{
	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/update-reset-password`,{password,otp,token}
	)
}

