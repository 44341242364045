import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteOrderById,
  deleteOrders,
  getOrders,
} from "../../Functions/orders";
// import DateFnsUtils from "@date-io/date-fns";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";
import { getClientInfo } from "../../Functions/auth";

const ClientName = ({ clientId }) => {
  const [client, setClient] = useState("");

  const getClientInfoRequest = async () => {
    if (clientId === undefined) {
      setClient("User Not Available");
    } else {
      const clientInfoResponse = await getClientInfo(clientId);
      setClient(clientInfoResponse.data.client.name);
    }
  };

  useEffect(() => {
    getClientInfoRequest();
  }, []);

  return <div>{client}</div>;
};

const AdminOrders = ({ history }) => {
  const [orders, setOrders] = useState([]);
  const [select, setSelect] = useState("");

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history.push("/admin");
    }
    getOrders()
      .then((res) => {
        console.log(res, "=> response");
        setOrders(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getOrdersData = () => {
    getOrders()
      .then((res) => {
        // console.log(res, "=> response");
        setOrders(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const Orders = () => {
    setTimeout(() => {
      // getOrders().then((res)=>setOrders(res.data)).catch(err=>console.log(err));
    }, 5000);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history.push("/admin");
  };

  const html_table_to_excel = (products, orderedBy, totalAmount, orderId) => {
    let newProductList = [];

    products.map((product) => {
      newProductList.push({
        orderId: orderId,
        ...product,
        orderedBy: orderedBy,
        totalAmount: totalAmount,
      });
    });

    let binaryWS = XLSX.utils.json_to_sheet(newProductList);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Order");

    // export your excel
    XLSX.writeFile(wb, `${orderId}.xlsx`);
  };
  return (
    <div className="d-flex" id="wrapper">
      {console.log(orders)}

      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Orders</h4>

        <div class="container-fluid mt-4">
          <form>
            <select
              className="form-select mt-4 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option>Select Store Type</option>
              <option value="B2B">Online Store</option>
              <option value="Retail">Offline Store</option>
            </select>
          </form>
          {select === "Retail" ? (
            <>
              <div
                class="container-fluid"
                style={{ marginTop: "18px", padding: 0 }}
              >
                <button
                  className="button btn-danger mb-3"
                  id="sidebarToggle"
                  onClick={() => {
                    deleteOrders();
                    getOrdersData();
                  }}
                >
                  Delete All
                </button>
              </div>
              <select
                className="form-select mt-2 mr-3 mb-5"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                aria-label="Default select example"
                onChange={(e) => {}}
              >
                <option>Select Product Group</option>
                <option value="B2B">JVD</option>
                <option value="Retail">Arial</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Second Product Category</option>
                <option value="B2B">Category 1</option>
                <option value="Retail">Category 2</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Third Product Category</option>
                <option value="B2B">Category 1</option>
                <option value="Retail">Category 2</option>
              </select>
              <select
                className="form-select mt-2 mr-3 mb-5"
                aria-label="Default select example"
                style={{
                  display: "inline-block",
                  width: "200px",
                }}
                onChange={(e) => {}}
              >
                <option>Select Duration</option>
                <option value="B2B">Yesterday</option>
                <option value="Retail">Last 3 days</option>
                <option value="Retail">Last week</option>
                <option value="Retail">Last 2 week</option>
                <option value="Retail">Last month</option>
                <option value="Retail">Last quater</option>
                <option value="Retail">Last year</option>
              </select>

              {/* <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date picker inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              /> */}
              <table className="table table-bordered">
                <thead className="thead-light table-head-background">
                  <tr>
                    <th scope="col">Order_Id</th>
                    <th scope="col">Ordered_By</th>
                    <th scope="col">View Order</th>
                    <th scope="col">Export Order</th>
                    <th scope="col">Total amount</th>
                    <th scope="col">Ordered at</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr>
                      <td>{order._id}</td>
                      <td>
                        <ClientName clientId={order.orderedBy} />
                      </td>
                      <td>
                        <button
                          className="button"
                          onClick={() => {
                            dispatch({
                              type: "SINGLE_ORDER",
                              payload: order,
                            });
                            history.push("/admin/singleOrder");
                          }}
                        >
                          <u>View</u>
                        </button>
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            html_table_to_excel(
                              order.products,
                              order.orderedBy,
                              order.totalAmount,
                              order._id
                            );
                          }}
                        >
                          <u>Export Order</u>
                        </div>
                      </td>
                      <td>
                        <span style={{ fontFamily: "helvetica" }}>
                          {typeof order.totalAmount !== "undefined"
                            ? order.totalAmount
                            : 0}
                        </span>
                      </td>
                      <td>
                        on {new Date(order.createdAt).toLocaleDateString()} at{" "}
                        {new Date(order.createdAt).toLocaleTimeString()}
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            deleteOrderById(order._id);
                            getOrdersData();
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : select === "B2B" ? (
            <table className="table table-bordered">
              <thead className="thead-light table-head-background">
                <tr>
                  <th scope="col">Order_Id</th>
                  <th scope="col">Ordered_By</th>
                  <th scope="col">View Order</th>
                  <th scope="col">Export Order</th>
                  <th scope="col">Total amount</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Ordered at</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>test</td>
                  <td>test</td>
                  <td>test</td>
                  <td>test</td>
                  <td>test</td>
                  <td>test</td>
                  <td>test</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
