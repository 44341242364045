import React, { useState } from "react";
import logo from "../Images/brand-white.png";
import { Link, useHistory } from "react-router-dom";
import { updatePasswordWithOtp } from "../Functions/auth";
import { useSnackbar } from "notistack";

const UpdatePassword = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("forgotPasswordToken");
    updatePasswordWithOtp(password, otp, token).then((res) => {
      if (res.status == 200) {
        enqueueSnackbar(res.data.message,{
            variant:'success',
            preventDuplicate:true,
            autoHideDuration:3000
        })
        localStorage.removeItem('forgotPasswordToken')
        history.push('/existing-user')
      }
      else{
        enqueueSnackbar(res.data.message,{
            variant:'error',
            preventDuplicate:true,
            autoHideDuration:3000
        })
      }
    });
    console.log("Here is OTP and Password:", otp, password, token);
  };
  return (
    <div className="container mb-5">
      <Link to="/">
        <img src={logo} alt="AH International" className="logoImg" />
      </Link>
      <h3 style={{ color: "a7a936" }} className="text-center">
        Enter Otp
      </h3>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <form onSubmit={handlePasswordSubmit}>
              <label>Enter Received Otp</label>
              <input
                className="form-control"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="XXXXXX"
              />
              <label>Create New Password:</label>

              <input
                className="form-control"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                className="button mt-3"
                style={loading ? { pointerEvents: "none" } : {}}
              >
                {loading ? "Loading.." : "Reset Password"}
              </button>
            </form>
            <h5
              className="mt-4 text-center"
              onClick={() => history.push("/existing-user")}
              style={{ cursor: "pointer" }}
            >
              Back to login!
            </h5>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
