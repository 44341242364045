import axios from "axios";

export const createProduct = async (product) => {
  return await axios.post(
    `${process.env.REACT_APP_BASEURL}/api/create-product`,
    { product },
    {}
  );
};

export const getProducts = async () => {
  return await axios.get(`${process.env.REACT_APP_BASEURL}/api/products`);
};

export const createProducts = async (productlist) => {
  return await axios.post(
    `${process.env.REACT_APP_BASEURL}/api/productlist/`,
    { productlist },
    {}
  );
};

export const getProductById = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_BASEURL}/api/product/${productId}`
  );
};

export const deleteProductById = async (productId) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASEURL}/api/product/${productId}`
  );
};

export const deleteAllProduct = async () => {
  return await axios.delete(
    `${process.env.REACT_APP_BASEURL}/api/deleteAll/`
  );
};
