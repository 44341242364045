import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteClientById,
  getB2BUsers,
  getRetailUsers,
} from "../../Functions/auth";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";
import { useDispatch } from "react-redux";

const AdminClients = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [retail, setRetail] = useState([]);
  const [select, setSelect] = useState("");

  let dispatch = useDispatch();

  const deleteUser = async (clientId) => {
    const deleteClientResponse = await deleteClientById(clientId);
    console.log(deleteClientResponse);
  };

  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history.push("/admin");
    }
    getB2BUsers()
      .then((res) => setUsers(res.data.users))
      .catch((err) => console.log(err));

    getRetailUsers()
      .then((res) => setRetail(res.data.retail))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(users, "in state");
  }, [users]);

  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3 scrollbar">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-2">Clients</h4>

        <div class="container-fluid mt-4">
          <form>
            <select
              className="form-select mt-4 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option>Select Client Type</option>
              <option value="B2B">B2B Users</option>
              <option value="Retail">Retail Users</option>
            </select>
          </form>
        </div>
        {select === "B2B" ? (
          <>
            <h4 className="mt-4 content-subheading ml-2">B2B Clients</h4>
            <div class="container-fluid horizontal-scrollable">
              <table className="table table-bordered">
                <thead className="thead-light table-head-background">
                  <tr>
                    {/* <th scope="col">Role</th> */}
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Phone no.</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Department</th>
                    <th scope="col">Address</th>
                    <th scope="col"></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users !== null &&
                    users.map((user) => (
                      <tr>
                        {/* <td>{user.rol}</td> */}
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.company}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.desg}</td>
                        <td>{user.dep}</td>
                        <td>{user.address}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              deleteClientById(user._id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <button
                            className="button"
                            onClick={() => {
                              dispatch({
                                type: "SINGLE_B2B_CLIENT",
                                payload: user,
                              });
                              history.push("/admin/singleB2BClient");
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : select === "Retail" ? (
          <>
            <h4 className="mt-4 content-subheading ml-2">Retail Clients</h4>
            <div class="container-fluid horizontal-scrollable">
              <table className="table table-head-background table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone no.</th>
                    <th scope="col">Address</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {retail !== undefined &&
                    retail.map((retailUser) => (
                      <tr>
                        <td>{retailUser.name}</td>
                        <td>{retailUser.email}</td>
                        <td>{retailUser.phoneNumber}</td>
                        <td>{retailUser.address}</td>
                        {/* <td>
                          <button className="button" onClick={() => {}}>
                            View Details
                          </button>
                        </td> */}
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              deleteClientById(retailUser._id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <button
                            className="button"
                            onClick={() => {
                              dispatch({
                                type: "SINGLE_RETAIL_CLIENT",
                                payload: retailUser,
                              });
                              history.push("/admin/singleRetailClient");
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdminClients;
