import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOrder } from '../Functions/orders';
import Nav from '../Nav/HeaderNav';
import { Link } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { useSnackbar } from 'notistack';

const Cart = () => {
	const [discount, setDiscount] = useState(0);
	const { enqueueSnackbar } = useSnackbar();

	const [value, setValue] = React.useState('cart');

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue === 'scan') {
			history.push('/product-scan');
		} else if (newValue === 'cart') {
			history.push('/cart');
		}
	};

	var productsList = [];
	const history = useHistory();
	const { user, list } = useSelector((state) => ({ ...state }));

	const [userData, setUserData] = useState();

	const getUserData = async () => {
		const userDataRes = await localStorage.getItem('userData');
		if (userDataRes === null) {
			history.push('/existing-user');
		} else {
			setUserData(userDataRes);
		}
	};

	useEffect(() => {
		getUserData();
	}, []);

	let dispatch = useDispatch();
	const handleRemove = (product) => {
		console.log(product);

		const newList = [];

		for (let index = 0; index < list.length; index++) {
			if (list[index]._id !== product) {
				newList.push(list[index]);
			}
		}
		dispatch({
			type: 'UPDATE_LIST',
			payload: newList,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(list);
		let totalAmount = 0;
		list.map((p) => {
			totalAmount = totalAmount + p.mrp * p.quantityBought;
		});
		createOrder(list, user.id, totalAmount, discount)
			.then((res) => {
				console.log(res);
				dispatch({
					type: 'EMPTY_LIST',
					payload: [],
				});
				enqueueSnackbar('Your Order has been Placed', {
					variant: 'success',
					preventDuplicate: true,
				});
			})
			.catch((err) => console.log(err));
	};

	const modifyQuanity = (operation, product) => {
		const newList = [];

		for (let index = 0; index < list.length; index++) {
			if (list[index]._id == product) {
				if (operation === 'add') {
					newList.push({
						...list[index],
						quantityBought:
							parseInt(list[index].quantityBought) + 1,
					});
				} else if (operation === 'substract') {
					newList.push({
						...list[index],
						quantityBought:
							parseInt(list[index].quantityBought) - 1,
					});
				}
			} else {
				newList.push(list[index]);
			}
		}
		dispatch({
			type: 'UPDATE_LIST',
			payload: newList,
		});
	};

	return (
		<div
			className='container'
			style={{
				height: 'calc(100vh - 70px)',
			}}
		>
			<Nav />
			<h4 className='text-center'>Cart</h4>
			<div
				className='d-flex flex-column justify-content-between'
				style={{
					height: 'calc(100% - 170px)',
				}}
			>
				<div className='d-flex flex-column justify-content-start pb-5'>
					{list.map((product) => (
						<div key={product._id} className='card mt-3'>
							<div className='card-body'>
								<b>Brand:</b> {product.productGroup}
								<br />
								<b>Product Category:</b> {product.subGroup2}
								<br />
								<b>Model Number:</b> {product.productId}
								<br />
								<b>Product Name:</b> {product.productName}
								<br />
								<b>Price: </b>
								<span style={{ fontFamily: 'helvetica' }}>
									&#8377; {product.mrp}
								</span>
								<br />
								<div
									style={{
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<div
										className='float-left'
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<div
											style={{
												color: '#072d19',
												marginLeft: '0.7rem',
											}}
											onClick={() =>
												modifyQuanity(
													'substract',
													product._id
												)
											}
										>
											<IndeterminateCheckBoxIcon
												style={{ fontSize: '2.5rem' }}
											/>
										</div>
										{product.quantityBought}
										<div
											style={{
												color: '#072d19',
												marginRight: '0.7rem',
											}}
											onClick={() =>
												modifyQuanity(
													'add',
													product._id
												)
											}
										>
											<AddBoxIcon
												style={{ fontSize: '2.5rem' }}
											/>
										</div>
									</div>
									<button
										onClick={() =>
											handleRemove(product._id)
										}
										className='button float-right'
									>
										Remove Item
									</button>
								</div>
							</div>
						</div>
					))}
					{list.length !== 0 ? (
						<button
							className='button mt-5 mb-5'
							onClick={handleSubmit}
						>
							Submit Order
						</button>
					) : (
						<p className='text-center mt-5'>Your cart is empty.</p>
					)}
				</div>
				<Paper
					style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					elevation={3}
				>
					<BottomNavigation
						value={value}
						style={{ backgroundColor: '#fdf6d8' }}
						onChange={handleChange}
						showLabels={true}
					>
						<BottomNavigationAction
							label='Scan Products'
							value='scan'
							color='#072d19'
							style={{
								color: '#072d19',
							}}
							icon={<CropFreeIcon />}
						/>
						<BottomNavigationAction
							label='Cart'
							value='cart'
							style={{
								color: '#072d19',
							}}
							icon={<ShoppingCartIcon />}
						/>
					</BottomNavigation>
				</Paper>
				{/* <div className="row">
          <div
            className="col ml-1 mr-1 mt-1 d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#072d19",
              height: "60px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Link style={{ color: "#000" }} to="/product-scan">
              <i
                style={{
                  fontSize: "30px",
                  color: "#fdf6dc",
                  margin: "0rem 0.3rem",
                  color: "#fdf6dc",
                }}
                className="fas fa-qrcode"
              ></i>
            </Link>
          </div>
          <div
            className="col ml-1 mr-1 mt-1 d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#072d19",
              height: "60px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Link style={{ color: "#000" }} to="/cart">
              <i
                style={{
                  fontSize: "30px",
                  color: "#fdf6dc",
                  margin: "0rem 0.3rem",
                  color: "#fdf6dc",
                }}
                className="fas fa-shopping-cart"
              ></i>
            </Link>
          </div>
        </div> */}
			</div>
		</div>
	);
};

export default Cart;
