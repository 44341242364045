import axios from "axios";

export const getOrders = async () => {
	return await axios.get(`${process.env.REACT_APP_BASEURL}/api/orders`);
};

export const getSingleOrder = async () => {
	return await axios.get(`${process.env.REACT_APP_BASEURL}/api/orders`);
};

export const createOrder = async (
	products,
	orderedBy,
	totalAmount,
	discount
) => {
	console.log(products);
	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/order/`,
		{ products, orderedBy, totalAmount, discount },
		{}
	);
};

export const filterOrder = async (reqBody) => {
	return await axios.post(
		`${process.env.REACT_APP_BASEURL}/api/order/`,
		reqBody,
		{}
	);
};

export const deleteOrderById = async (orderId) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASEURL}/api/delete-order/${orderId}`
	);
};

export const deleteOrders = async (orderId) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASEURL}/api/deleteOrders`
	);
};
