import { Hidden } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import HeaderCarousel from "../Carousel/HeaderCarousel";
import logo from "../Images/brand-white.png";
import details from "../Images/details.png";

const Home = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100vw",
        }}
      >
        <Link to="/">
          <img src={logo} alt="AH International" className="logoImg" />
        </Link>
        <Hidden mdDown>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              width: "fit-content",
              marginRight: "1.5rem",
            }}
          >
            <img
              src={details}
              alt=""
              style={{
                height: "70px",
                width: "350px",
              }}
            />
          </div>
        </Hidden>
        <Hidden lgUp>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              width: "fit-content",
              marginRight: "0.5rem",
            }}
          >
            <img
              src={details}
              alt=""
              style={{
                height: "50px",
                width: "250px",
              }}
            />
          </div>
        </Hidden>
      </div>
      <HeaderCarousel />
      <div className="row mt-4 pt-2 mb-5">
        <div className="col-2 dHide"></div>
        <div className="col-md-6 col-4 btn-center">
          <Link
            style={{ color: "#8F774A", textDecoration: "none" }}
            to="/existing-user"
          >
            <button className="button btn-float-right">Sign In</button>
          </Link>
        </div>
        <div className="col-md-6 col-4 btn-center">
          <Link
            style={{ color: "#8F774A", textDecoration: "none" }}
            to="/new-user"
          >
            {" "}
            <button className="button">Sign Up </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
