import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getOrders } from "../../Functions/orders";
import { useSelector, useDispatch } from "react-redux";
import { Hidden                                                 } from "@material-ui/core";
import * as XLSX from "xlsx";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";

const OrderSingle = ({ history }) => {
  const [orders, setOrders] = useState([]);
  let dispatch = useDispatch();
  const { admin } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    // if (window.localStorage.getItem("userName") !== "admin") {
    //   history.push("/admin");
    // }
    // getOrders()
    //   .then((res) => setOrders(res.data))
    //   .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(admin, "single Order");
  }, [admin]);

  const Orders = () => {
    setTimeout(() => {
      // getOrders().then((res)=>setOrders(res.data)).catch(err=>console.log(err));
    }, 5000);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history.push("/admin");
  };

  //   const html_table_to_excel = (products, orderedBy, totalAmount, orderId) => {
  //     let newProductList = [];

  //     products.map((product) => {
  //       newProductList.push({
  //         orderId: orderId,
  //         ...product,
  //         orderedBy: orderedBy,
  //         totalAmount: totalAmount,
  //       });
  //     });

  //     let binaryWS = XLSX.utils.json_to_sheet(newProductList);

  //     // Create a new Workbook
  //     var wb = XLSX.utils.book_new();
  //     // Name your sheet
  //     XLSX.utils.book_append_sheet(wb, binaryWS, "Order");

  //     // export your excel
  //     XLSX.writeFile(wb, `${orderId}.xlsx`);
  //   };
  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>{" "}
        <h4 className="mt-4 ml-2">Order ID: {admin._id}</h4>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h6 className="mt-4 ml-2">Ordered By: {admin.orderedBy}</h6>
            <h6 className="mt-4 ml-2">
              Ordered Created on{" "}
              {new Date(admin.createdAt).toLocaleDateString()} at{" "}
              {new Date(admin.createdAt).toLocaleTimeString()}
            </h6>
          </div>
          <button
            className="button m-5 mt-4"
            id="sidebarToggle"
            onClick={() => {
              history.push("/admin/orders");
            }}
          >
            Back
          </button>
        </div>
        <br />
        <br />
        <div class="container-fluid">
          <table className="table table-bordered">
            <thead className="thead-light table-head-background">
              <tr>
                <th scope="col">Product ID</th>
                <th scope="col">Product Name</th>
                <th scope="col">Model No.</th>
                <th scope="col">Purchase Currency</th>
                <th scope="col">Cost Purchase Currency</th>
                <th scope="col">Sell Tax</th>
                <th scope="col">HSN Code</th>
                <th scope="col">Product Type</th>
                <th scope="col">Product Category</th>
                <th scope="col">Quantity Bought</th>
                <th scope="col">Specification</th>
                <th scope="col">Brand</th>
                <th scope="col">Country of Origin</th>
                <th scope="col">Case Pack</th>
                <th scope="col">MRP</th>
              </tr>
              {admin.products.map((product) => (
                <tr>
                  <td>{product.productId}</td>
                  <td>{product.subGroup2}</td>
                  <td>{product.manufacturerName}</td>
                  <td>{product.purchaseCurrency}</td>
                  <td>{product.costPurchasePrice}</td>
                  <td>{product.sellTax}</td>
                  <td>{product.productType}</td>
                  <td>{product.subGroup1}</td>
                  <td>{product.hsnCode}</td>
                  <td>{product.hsnCode}</td>
                  <td>{product.hsnCode}</td>
                  <td>{product.countryOfOrigin}</td>
                  <td>{product.hsnCode}</td>
                  <td>{product.hsnCode}</td>

                  <td>{product.mrp * product.quantityBought}</td>
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderSingle;
