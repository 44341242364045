import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { getProductById } from '../Functions/products';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Nav from '../Nav/HeaderNav';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useSnackbar } from 'notistack';
// import QrScanner from 'path/to/qr-scanner.min.js'; // if using plain es6 import
import QrScanner from 'qr-scanner'; //if using plain es6 import
const ScanProduct = ({}) => {
	const [scanResultWebCam, setScanResultWebCam] = useState('');
	const [product, SetProduct] = useState({});
	const [quantity, setQuantity] = useState(0);
	const history = useHistory();
	const { user, list } = useSelector((state) => ({ ...state }));
	const { enqueueSnackbar } = useSnackbar();

	var qrScanner;
	const getUserData = async () => {
		const userData = await localStorage.getItem('userData');
		if (userData === null) {
			history.push('/existing-user');
		}
	};

	useEffect(() => {
		getUserData();
		if (document.getElementById('qr-video') != null) {
			qrScanner = new QrScanner(
				document.getElementById('qr-video'),
				(result) => {
					setScanResultWebCam(result);
					getProductById(result)
						.then((res) => {
							SetProduct(res.data.product);
						})
						.catch((err) => console.log(err));
				}
			);
			qrScanner.start();
		}
	}, []);

	const [value, setValue] = React.useState('scan');

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue === 'scan') {
			history.push('/product-scan');
		} else if (newValue === 'cart') {
			history.push('/cart');
		}
	};

	// useEffect(() => {
	// 	getProductById(scanResultWebCam)
	// 		.then((res) => {
	// 			console.log(res.data.product);
	// 			SetProduct(res.data.product);
	// 		})
	// 		.catch((err) => console.log(err));
	// }, [scanResultWebCam]);
	let dispatch = useDispatch();
	const handleErrorWebCam = (error) => {
		console.log(error);
	};
	const handleScanWebCam = (result) => {
		console.log(typeof result);
		setScanResultWebCam(result);
	};
	var productsList = [];
	if (list.length != 0) {
		productsList.push(...list);
	}
	productsList.push(product);
	const handleAddToList = (e) => {
		e.preventDefault();
		if (quantity > 0) {
			product.quantityBought = quantity;
			console.log(product.quantityBought);
			dispatch({
				type: 'ADD_TO_LIST',
				payload: productsList,
			});
			enqueueSnackbar('Product Added to Cart', {
				variant: 'success',
				preventDuplicate: true,
				autoHideDuration: 1500,
			});
			setScanResultWebCam('');
			setQuantity(0);
		} else {
			enqueueSnackbar('Quantity cannot be left 0', {
				variant: 'error',
				preventDuplicate: true,
				autoHideDuration: 1500,
			});
		}
	};

	// const handleClick = () =>{
	// 	qrScanner.start();
	// }
	console.log('user is', user);
	console.log('list is', list);

	return (
		<div
			style={{
				height: 'calc(30vh - 70px)',
			}}
			className='container'
		>
			<Nav />
			<h3 className='logogs'>Scan Product</h3>
			<video
				id='qr-video'
				style={{
					width: '95%',
					marginLeft: '2.5%',
					marginTop: '5%',
					marginBottom: '5%',
				}}
			></video>

			{/* <button onClick={handleClick}>Good</button> */}

			<div
				className='d-flex flex-column justify-content-between'
				style={{
					height: 'calc(100% - 170px)',
				}}
			>
				<div className='row'>
					<div className='col-md-4'></div>
					{/* <div className='col-md-3'>
						<QrReader
							delay={300}
							onError={handleErrorWebCam}
							onScan={handleScanWebCam}
							style={{ backgroundColor: 'black' }}
						/>
					</div> */}
					<div className='col-md-5'></div>
				</div>
				<Paper
					style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					elevation={3}
				>
					<BottomNavigation
						value={value}
						style={{ backgroundColor: '#fdf6d8' }}
						onChange={handleChange}
						showLabels={true}
					>
						<BottomNavigationAction
							label='Scan Products'
							value='scan'
							color='#072d19'
							style={{
								color: '#072d19',
							}}
							icon={<CropFreeIcon />}
						/>
						<BottomNavigationAction
							label='Cart'
							value='cart'
							style={{
								color: '#072d19',
							}}
							icon={<ShoppingCartIcon />}
						/>
					</BottomNavigation>
				</Paper>
			</div>
			{scanResultWebCam !== '' ? (
				<div id='myModal' class='modal' style={{ display: 'block' }}>
					{product !== null ? (
						<div class='modal-content'>
							<div className='card'>
								<div className='card-body'>
									<b>Brand:</b> {product.productGroup}
									<br />
									<b>Product Category:</b> {product.subGroup2}
									<br />
									<b>Model Number:</b> {product.productId}
									<br />
									<b>Product Name:</b> {product.productName}
									<br />
									<b>Price: </b>
									<span style={{ fontFamily: 'helvetica' }}>
										&#8377; {product.mrp}
									</span>
									<br />
									<span style={{ display: 'inline-flex' }}>
										<b>Quantity:</b>{' '}
										<input
											type='number'
											value={quantity}
											style={{
												width: '30%',
												marginLeft: '1rem',
												fontFamily: 'helvetica',
											}}
											onChange={(e) =>
												setQuantity(e.target.value)
											}
											placeholder='Enter Quantity'
											className='form-control'
										/>
									</span>
								</div>
								<div className='card-footer'>
									<button
										className='button close-modal'
										onClick={handleAddToList}
									>
										Add to list
									</button>
									<button
										className='button close-modal ml-4'
										onClick={() => {
											setScanResultWebCam('');
											setQuantity(0);
										}}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default ScanProduct;
