import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import rootReducer from './Reducers';
import { SnackbarProvider } from 'notistack';

//Store
const store = createStore(rootReducer,composeWithDevTools());

ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
  <SnackbarProvider
    maxSnack={3}
    autoHideDuration={3000}
  >
    <App />
    </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
