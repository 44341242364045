import React, { useState } from 'react';
import { signup } from '../Functions/auth';
import { useHistory } from 'react-router-dom';
import data from '../countries.json';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Hidden } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const RetailUser = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [loading, setLoading] = useState(false);
	// const [dis, setDis] = useState('');
	// const [country, setCountry] = useState('');
	// const [pincode, setPincode] = useState('');
	const [states, setState] = useState('');
	const [password, setPassword] = useState('');
	// const [adrs1, setAdrs1] = useState('');
	// const [adrs2, setAdrs2] = useState('');
	let history = useHistory();
	let dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const onHandleSumbit = (e) => {
		e.preventDefault();
		console.log(states);
		if (name === '' || email === '' || phone === '' || password === '') {
			enqueueSnackbar('Please fill all the fields', {
				variant: 'error',
				preventDuplicate: true,
				autoHideDuration: 1500,
			});
		} else {
			setLoading(true);
			signup(name, email, password, phone, 'retail')
				.then((res) => {
					console.log(res);
					if (
						res.data.message === 'User was registered successfully!'
					) {
						history.push('/existing-user');
						enqueueSnackbar(
							'Your Account has been succesfully registered',
							{
								variant: 'success',
								preventDuplicate: true,
							}
						);
					}
					setLoading(false);
				})
				.catch((err) => {
					console.log('some error occured :', err);
					enqueueSnackbar(err.response.data.message, {
						variant: 'error',
						preventDuplicate: true,
					});
					setLoading(false);
				});
		}
	};
	return (
		<div>
			<form onSubmit={onHandleSumbit}>
				<label>Full Name</label>
				<input
					className='form-control'
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder='First and Last Name'
				/>
				<label>Email address</label>
				<input
					type='text'
					className='form-control'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder='email@address.com'
				/>
				<label>Password</label>
				<input
					className='form-control'
					type='password'
					value={password}
					placeholder='********'
					onChange={(e) => setPassword(e.target.value)}
				/>
				<label>Phone Number</label>
				<input
					className='form-control'
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					placeholder='(+123)-45678901'
				/>
				{/* <label>Address</label>
        <div className="row">
          <select
            className="form-select"
            style={{
              width: "95%",
              margin: "0 0.8rem 1.5rem 0.8rem",
            }}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          >
            <option>Choose your country</option>
            {data.countryList.map((country) => {
              return (
                <option key={country} value={country}>
                  {country}
                </option>
              );
            })}
          </select>
          <div className="col-md-6">
            <input
              className="form-control"
              value={states}
              onChange={(e) => setState(e.target.value)}
              placeholder="State"
            />
          </div>
          <Hidden mdUp>
            <div className="col-md-6 mt-4">
              <input
                className="form-control"
                value={pincode}
                style={{ fontFamily: "helvetica" }}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Zip Code"
              />
            </div>
          </Hidden>
          <Hidden smDown>
            <div className="col-md-6">
              <input
                className="form-control"
                value={pincode}
                style={{ fontFamily: "helvetica" }}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Zip Code"
              />
            </div>
          </Hidden>
        </div>
        <br />
        <input
          className="form-control"
          value={dis}
          onChange={(e) => setDis(e.target.value)}
          placeholder="District"
        />
        <br />
        <input
          className="form-control"
          value={adrs1}
          onChange={(e) => setAdrs1(e.target.value)}
          placeholder="Address Line 1"
        />
        <br />
        <input
          className="form-control"
          value={adrs2}
          onChange={(e) => setAdrs2(e.target.value)}
          placeholder="Address Line 2"
        /> */}
				<button
					className='button mt-3 mb-5'
					style={loading ? { pointerEvents: 'none' } : {}}
				>
					{loading ? 'Loading.' : 'Submit'}
				</button>
			</form>
		</div>
	);
};

export default RetailUser;
