import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { createProducts } from "../../Functions/products";
import CreateProduct from "../../Forms/CreateProduct";
import SideNav from "../../Nav/SideNav";
import SideNavToggle from "../../Nav/SidenavToggle";
import { Hidden } from "@material-ui/core";

const AdminCreateProduct = ({ history }) => {
  const [select, setSelect] = useState("");
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (window.localStorage.getItem("userName") !== "admin") {
      history.push("/admin");
    }
  });

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("userName");
    history.push("/admin");
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      setProducts(d);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    products.map((p) => {
      if (p.batch === "N") {
        p.batch = false;
      }
      if (p.inventory === "Y") {
        p.inventory = true;
      }
    });
    console.log(products);
    createProducts(products)
      .then((res) => {
        if (res.data === "All Products are inserted") {
          toast.success("Successfully added the products to database");
          history.push("/admin/add-product");
        } else {
          toast.error("Products could not be added to the database");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="d-flex" id="wrapper">
      <SideNav />
      <div id="page-content-wrapper" className="ml-3">
        <Hidden smDown>
          <SideNavToggle initialIcon={"cross"} />
        </Hidden>
        <Hidden mdUp>
          <SideNavToggle initialIcon={"bar"} />
        </Hidden>
        <h4 className="mt-3 content-heading ml-4">Add Products</h4>
        <div class="container-fluid">
          <form>
            <select
              className="form-select mt-5 mb-5"
              aria-label="Default select example"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option>Select Product Type</option>
              <option value="Single Product">Add Single Product</option>
              <option value="Excel Sheet">Add Excel Sheet</option>
            </select>
            {select === "Single Product" ? (
              <CreateProduct />
            ) : select === "Excel Sheet" ? (
              <div>
                <label>Choose File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
                <button onClick={handleSubmit} className="button mt-2">
                  Submit
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateProduct;
