import React, { useEffect, useState } from "react";
import logo from "../Images/brand-white.png";
import { useDispatch } from "react-redux";
import "../index.css";
import "../Pages/styles.css";
import { Link, useHistory } from "react-router-dom";

const SideNavToggle = ({ initialIcon }) => {
  const [iconClass, setIconClass] = useState();

  useEffect(() => {
    console.log(initialIcon);

    if (initialIcon === "cross") {
      setIconClass("fa fa-times ml-4 mt-3");
    } else if (initialIcon === "bar") {
      setIconClass("fas fa-bars ml-4 mt-3");
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );

    if (iconClass === "fas fa-bars ml-4 mt-3") {
      setIconClass("fa fa-times ml-4 mt-3");
    } else if (iconClass === "fa fa-times ml-4 mt-3") {
      setIconClass("fas fa-bars ml-4 mt-3");
    }
  };

  const history = useHistory();

  return <i className={iconClass} id="sidebarToggle" onClick={handleClick}></i>;
};

export default SideNavToggle;
